import { Injectable } from '@angular/core';
import { AuthenticationService, IAuthRouter } from '@ngx-ivengi/authentication';
import { StateService, TargetState, TransitionService } from '@uirouter/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthRouterService implements IAuthRouter {
  constructor(
    private stateService: StateService,
    private transitionService: TransitionService,
    private authenticationService: AuthenticationService,
  ) {
    this.checkAuthentication(this.stateService.$current).then(result => {
      if (typeof result === 'object' && result instanceof TargetState) {
        if (this.authenticationService.config.isSamlAuthenticationEnabled()) {
          this.authenticationService.doSamlLogin();
        } else {
          this.stateService.go(this.authenticationService.getRedirectState());
        }
      }
    });

    // Register for transition change for authentication checks
    const criteria = { entering: true };
    this.transitionService.onEnter(criteria, transition => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.checkAuthentication(transition.to(), { ...transition.params() });
    });
  }

  public goToRedirectRoute(): void {
    if (this.authenticationService.config.isSamlAuthenticationEnabled()) {
      this.authenticationService.doSamlLogin();
    } else {
      this.stateService.go(this.authenticationService.config.getRedirectState());
    }
  }

  public isInsecureRoute(route: string): boolean {
    const stateDef = this.stateService.get(route);
    return (stateDef.data && stateDef.data.insecure && stateDef.data.insecure === true) === true;
  }

  public getCurrentRoute(): string {
    return this.stateService.$current.name;
  }

  public onLoginSuccess(): void {
    if (this.authenticationService.config.isSamlAuthenticationEnabled()) {
      if (
        this.authenticationService.isInsecureState(<string>this.stateService.current.name) &&
        this.stateService.current.name === this.authenticationService.config.getRedirectState()
      ) {
        this.stateService.go(this.authenticationService.config.getRootState());
      }
    } else {
      const redirect = setTimeout(() => {
        if (
          this.authenticationService.isInsecureState(<string>this.stateService.current.name) &&
          this.stateService.current.name === this.authenticationService.config.getRedirectState()
        ) {
          this.stateService.go(this.authenticationService.config.getRootState());
        }
      }, 200);
      this.transitionService.onBefore({}, () => {
        clearTimeout(redirect);
      });
    }
  }

  public checkAuthentication(state: any, params = null): Promise<any> {
    return new Promise<true | false | void | TargetState>(resolve => {
      // if state is insecure, always resolve state
      if (state.name === '' || (state.data && state.data.insecure && state.data.insecure === true)) {
        resolve(true);
      } else if (this.authenticationService.isAuthenticated() === false) {
        if (state.name.length > 0) {
          // prevent overriding deeplink when already set
          if (!this.authenticationService.getDeepLink()) {
            this.authenticationService.setDeepLink({ params, state: state.name });
          }
        }

        // if not authenticated, redirect to configured page
        if (this.authenticationService.config.isSamlAuthenticationEnabled()) {
          this.authenticationService.doSamlLogin();
        } else {
          resolve(this.stateService.target(this.authenticationService.getRedirectState()));
        }
      } else if (this.authenticationService.getDeepLink()) {
        const deepLink = { ...(<{ state: string; params: any }>this.authenticationService.getDeepLink()) };
        this.authenticationService.setDeepLink(null);
        resolve(this.stateService.target(deepLink.state, deepLink.params));
      } else if (state.data && state.data.scopes && state.data.scopes.length > 0) {
        let isAuthorizedForTransition = false;
        for (let i = 0; i < state.data.scopes.length; i += 1) {
          if (this.authenticationService.getAuthorizationScopesSync().indexOf(state.data.scopes[i]) !== -1) {
            isAuthorizedForTransition = true;
            break;
          }
        }

        if (isAuthorizedForTransition) {
          // if authorization scopes are configured for state and one ore more are met, resolve state
          resolve(true);
        } else {
          // if not authorized for state by scopes, redirect to unauthorized state
          resolve(this.stateService.target(this.authenticationService.getUnauthorizedState()));
        }
      } else {
        // otherwise, resolve state
        resolve(true);
      }
    });
  }
}
