import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from '@ngx-ivengi/layout';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { dynamicStateConfigFn, LocaleService } from '@ngx-ivengi/locale';
import { registerLocaleData } from '@angular/common';
import nl from '@angular/common/locales/nl';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_STATES } from './app.states';
import { AppComponent } from './app.component';
import { translations } from '../assets/i18n/translations';
import { routes } from '../assets/i18n/routes';
import { setLanguage } from './app.init';
import { AppConfig } from './app.config';
import { AppNotification } from './app.notification';
import { OrganizationHttpInterceptor } from './lib/organization.http-interceptor';
import { LoginAuthorizationCodeComponent } from './components/login/login-authorization-code.component';

const languages = Object.keys(translations);
const browserCultureLang = LocaleService.getBrowserCultureLang();
LocaleService.setTranslations(routes);
LocaleService.setAvailableLanguageISO(languages);
LocaleService.setDefaultLanguageISO(languages.indexOf(browserCultureLang) !== -1 ? browserCultureLang : languages[0]);
setLanguage();
registerLocaleData(nl);

export function initializeApp(appConfig: AppConfig): () => void {
  return () => appConfig.initializeApp();
}

export function appNotification(notification: AppNotification): () => void {
  return () => notification.initialize();
}

@NgModule({
  declarations: [AppComponent, LoginAuthorizationCodeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    TranslateModule.forRoot(),
    UIRouterModule.forRoot({
      otherwise: { state: 'dashboard' },
      deferIntercept: true,
      states: APP_STATES,
      config: dynamicStateConfigFn,
    }),
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right', closeButton: true }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: OrganizationHttpInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [AppConfig] },
    { provide: APP_INITIALIZER, useFactory: appNotification, multi: true, deps: [AppNotification] },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
