import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../whitelabel/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationHttpInterceptor implements HttpInterceptor {
  // eslint-disable-next-line class-methods-use-this
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.whiteLabel.number !== '0') {
      return next.handle(request.clone({ setHeaders: { 'X-Organization': environment.whiteLabel.number } }));
    }

    return next.handle(request);
  }
}
