export enum AccountScopes {
  CREATE = 'account:create',
  READ = 'account:read',
  UPDATE = 'account:update',
  DELETE = 'account:delete',
}

export enum ApplicationUserScopes {
  CREATE = 'application-user:create',
  READ = 'application-user:read',
  UPDATE = 'application-user:update',
  DELETE = 'application-user:delete',
}

export enum DashboardScopes {
  READ = 'dashboard:read',
}

export enum EnumScopes {
  CREATE = 'enum:create',
  READ = 'enum:read',
  UPDATE = 'enum:update',
  DELETE = 'enum:delete',
}

export enum I18nSettingsScopes {
  CREATE = 'i18n-settings:create',
  READ = 'i18n-settings:read',
  UPDATE = 'i18n-settings:update',
  DELETE = 'i18n-settings:delete',
}

export enum MasterAccountScopes {
  CREATE = 'master-account:create',
  READ = 'master-account:read',
  UPDATE = 'master-account:update',
  DELETE = 'master-account:delete',
}

export enum ProfileScopes {
  READ = 'profile:read',
  UPDATE = 'profile:update',
}

export enum SettingsScopes {
  CREATE = 'settings:create',
  READ = 'settings:read',
  UPDATE = 'settings:update',
  DELETE = 'settings:delete',
}

export enum UsernameScopes {
  CREATE = 'username:create',
  READ = 'username:read',
  UPDATE = 'username:update',
  DELETE = 'username:delete',
}

export enum AuthScope {
  LOGIN         = 'auth:login',
  REFRESH_TOKEN = 'auth:refresh-token',
  LOGOUT        = 'auth:logout',
}

export enum UserScope {
  ME              = 'user:me',
  READ            = 'user:read',
  CREATE_CLIENT   = 'user:create-client',
  CREATE_EMPLOYEE = 'user:create-employee',
  UPDATE_CLIENT   = 'user:update-client',
  UPDATE_EMPLOYEE = 'user:update-employee',
  DELETE          = 'user:delete',
  UPDATE_CLIENT_ACCOUNT = 'user:update-client-account',
}

export enum QuestionnaireScope {
  CREATE          = 'questionnaire:create',
  READ            = 'questionnaire:read',
  READ_MASTER     = 'questionnaire:read-master',
  UPDATE          = 'questionnaire:update',
  UPDATE_ARCHIVE  = 'questionnaire:update-archive',
  DELETE          = 'questionnaire:delete',
  COPY            = 'questionnaire:copy',
}

export enum QuestionCategoryScope {
  CREATE = 'question-category:create',
  READ   = 'question-category:read',
  UPDATE = 'question-category:update',
  DELETE = 'question-category:delete',
}

export enum QuestionScope {
  CREATE = 'question:create',
  READ   = 'question:read',
  UPDATE = 'question:update',
  DELETE = 'question:delete',
}

export enum WhiteLabelScope {
  CREATE = 'white-label:create',
  READ   = 'white-label:read',
  UPDATE = 'white-label:update',
  DELETE = 'white-label:delete',
}

export enum PlanningScope {
  CREATE = 'planning:create',
  READ   = 'planning:read',
  UPDATE = 'planning:update',
  DELETE = 'planning:delete',
}

export enum PlanningIterationScope {
  CREATE = 'planning-iteration:create',
  READ   = 'planning-iteration:read',
  UPDATE = 'planning-iteration:update',
  DELETE = 'planning-iteration:delete',
}

export enum SurveyScope {
  CREATE        = 'survey:create',
  READ          = 'survey:read',
  UPDATE        = 'survey:update',
  UPDATE_STATUS = 'survey:update-status',
  DELETE        = 'survey:delete',
}

export enum ResponseScope {
  CREATE = 'response:create',
  READ   = 'response:read',
  UPDATE = 'response:update',
  DELETE = 'response:delete',
}

export enum CostCenterScope {
  CREATE = 'cost-center:create',
  READ   = 'cost-center:read',
  UPDATE = 'cost-center:update',
  DELETE = 'cost-center:delete',
}

export enum LocationScope {
  CREATE = 'location:create',
  READ   = 'location:read',
  UPDATE = 'location:update',
  DELETE = 'location:delete',
}

export enum ReportScope {
  CREATE = 'report:create',
  READ   = 'report:read',
  UPDATE = 'report:update',
  DELETE = 'report:delete',
}

export enum BackofficeEnum {
  GOD = 'god',
  WHITELABEL = 'white-label'
}

export enum GenderEnum {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown',
}

export function getEnumValues(enumeration: any): string[] {
  return Object.keys(enumeration)
    .map(key => enumeration[key])
    .filter(value => typeof value === 'string');
}
