import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudConfig } from '@ngx-ivengi/crud';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingTypes } from '../../components/shared/shared.enum';
import { I18nSettings } from '../models/settings/i18n-settings.model';
import { Setting } from '../models/setting.model';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(protected crudConfig: CrudConfig, protected http: HttpClient) {}

  public getSettings(settingType: SettingTypes): Observable<I18nSettings> {
    switch (settingType) {
      default:
      case SettingTypes.LANGUAGES: {
        return new BehaviorSubject(new I18nSettings()).asObservable();
      }
    }
  }

  public saveSettings(settings: Setting) {
    return this.http.post(`${this.crudConfig.getApiUrl()}/settings/${settings.type}`, settings.toRequest());
  }
}
