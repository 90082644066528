import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { CrudConfig } from '@ngx-ivengi/crud';
import { InsecureRouteService, NavigationService, NavigationType, NavItem } from '@ngx-ivengi/layout';
import { UIRouter } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '@ngx-ivengi/locale';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService, RequestMethod } from '@ngx-ivengi/authentication';
import { SettingTypes } from './components/shared/shared.enum';
import { SettingService } from './lib/services/setting.service';
import { environment } from '../whitelabel/environment';
import { translations } from '../assets/i18n/translations';
import { AuthRouterService } from './lib/services/auth-router.service';
import { ApplicationUser } from './lib/models/application-user.model';
import { DataStoreService } from './lib/services/data-store.service';
import { I18nSettings } from './lib/models/settings/i18n-settings.model';
import { SsoService } from './lib/services/sso.service';
import {
  LocationScope,
  PlanningScope,
  QuestionCategoryScope,
  QuestionnaireScope,
  SurveyScope,
  UserScope,
  WhiteLabelScope
} from './components/shared/shared-scopes.enum';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  private isConfigured$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isConfigured: Observable<boolean> = this.isConfigured$.asObservable();
  private availableLanguages: string[] = [];
  private profile: ApplicationUser | null = null;
  private settingsService!: SettingService;
  private ssoService!: SsoService;

  constructor(
    private injector: Injector,
    private router: UIRouter,
    private crudConfig: CrudConfig,
    private insecureRouteService: InsecureRouteService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private authRouterService: AuthRouterService,
    private dataStoreService: DataStoreService,
  ) {}

  public async initializeApp(): Promise<void> {
    this.crudConfig.setApiUrl(environment.apiUrl);
    await this.setInsecureConfig();
    await this.setAuthentication();

    this.settingsService = this.injector.get(SettingService);
    this.ssoService = this.injector.get(SsoService);

    combineLatest([
      this.checkAuthenticationStatus(),
      this.ssoService.isEnabled(),
      this.getLanguages(),
      this.dataStoreService.getProfile(),
      this.ssoService.handleAuthorizationCode(),
    ]).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, ssoEnabled, availableLanguages, profile]: [void, boolean, string[], any, any]) => {
        // Start SSO if needed
        this.router.transitionService.onBefore(
          {
            to: state => state?.name === 'login' && ssoEnabled,
          },
          transition => {
            transition.abort();
            this.ssoService.startAuthentication();
          },
        );

        this.availableLanguages = availableLanguages;
        this.setTranslations();
        this.router.urlService.listen();
        this.router.urlService.sync();

        this.profile = profile;
        this.setNavigation(ssoEnabled);

        this.isConfigured$.next(true);
      },
    );
  }

  private getLanguages(): Observable<string[]> {
    return this.settingsService.getSettings(SettingTypes.LANGUAGES).pipe(
      map(setting => {
        const i18nSettings = setting as I18nSettings;
        return i18nSettings.languages instanceof Array && i18nSettings.languages.length > 0
          ? i18nSettings.languages
          : [LocaleService.getDefaultLanguageISO()];
      }),
      catchError(() => {
        return [LocaleService.getDefaultLanguageISO()];
      }),
    ) as Observable<string[]>;
  }

  public setNavigation(ssoEnabled: boolean): void {
    this.navigationService.items = [];

    // profile items
    this.navigationService.addItem(
      new NavItem({
        name: this.translateService.instant('header.profile.navigation.profile'),
        state: 'profile',
        type: NavigationType.Profile,
        divider: true,
        scopes: [UserScope.ME],
      }),
    );

    if (!ssoEnabled) {
      this.navigationService.addItem(
        new NavItem({
          name: this.translateService.instant('header.profile.navigation.logout'),
          state: 'logout',
          type: NavigationType.Profile,
          divider: true,
        }),
      );
    }
    // sidebar items
    this.navigationService
      .addItem(
        new NavItem({
          name: this.translateService.instant('sidebar.navigation.dashboard'),
          icon: 'monitor-dashboard',
          state: 'dashboard',
          stateOptions: { reload: true },
          type: NavigationType.Sidebar,
          scopes: [],
        }),
      ).addItem(new NavItem(
      {
        name: this.translateService.instant('sidebar.navigation.whiteLabel'),
        icon: 'star-box',
        state: 'white-label',
        type: NavigationType.Sidebar,
        scopes: [
          WhiteLabelScope.CREATE,
          WhiteLabelScope.READ,
          WhiteLabelScope.UPDATE,
          WhiteLabelScope.DELETE,
        ]
      }
    ))
      .addItem(new NavItem(
        {
          name: this.translateService.instant('sidebar.navigation.questionCategory'),
          icon: 'tag',
          state: 'question-category',
          type: NavigationType.Sidebar,
          scopes: [
            QuestionCategoryScope.CREATE,
            QuestionCategoryScope.READ,
            QuestionCategoryScope.UPDATE,
            QuestionCategoryScope.DELETE,
          ]
        }
      ))
      .addItem(new NavItem(
        {
          name: this.translateService.instant('sidebar.navigation.questionnaire'),
          icon: 'format-list-checkbox',
          state: 'questionnaire',
          type: NavigationType.Sidebar,
          scopes: [
            QuestionnaireScope.CREATE,
            QuestionnaireScope.READ,
            QuestionnaireScope.UPDATE,
            QuestionnaireScope.DELETE,
            QuestionnaireScope.COPY,
          ]
        }
      ))
      .addItem(new NavItem(
        {
          name: this.translateService.instant('sidebar.navigation.planning'),
          icon: 'progress-clock',
          state: 'planning',
          type: NavigationType.Sidebar,
          scopes: [
            PlanningScope.CREATE,
            PlanningScope.READ,
            PlanningScope.UPDATE,
            PlanningScope.DELETE,
            SurveyScope.CREATE,
            SurveyScope.READ,
            SurveyScope.UPDATE,
            SurveyScope.DELETE,
          ]
        }
      ))
      .addItem(new NavItem(
        {
          name: this.translateService.instant('sidebar.navigation.user'),
          icon: 'account',
          type: NavigationType.Sidebar,
          scopes: [
            UserScope.READ
          ],
          children: [
            new NavItem({
              name: this.translateService.instant('sidebar.navigation.client'),
              state: 'client'
            }),
            new NavItem({
              name: this.translateService.instant('sidebar.navigation.employee'),
              state: 'employee'
            })
          ]
        }
      ))
      .addItem(new NavItem(
        {
          name: this.translateService.instant('sidebar.navigation.place'),
          icon: 'earth',
          state: 'places',
          type: NavigationType.Sidebar,
          scopes: [LocationScope.CREATE],
          children: [
            new NavItem({
              name: this.translateService.instant('sidebar.navigation.costCenter'),
              state: 'cost-center',
              scopes: ['cost-center:create']
            }),
            new NavItem({
              name: this.translateService.instant('sidebar.navigation.location'),
              state: 'location',
              scopes: ['location:create']
            })
          ]
        }
      ));
  }

  private setTranslations(): void {
    const currentLanguage = LocaleService.getCurrentLanguageISO();

    LocaleService.setAvailableLanguageISO(this.availableLanguages);

    if (this.availableLanguages.indexOf(currentLanguage) === -1) {
      LocaleService.setDefaultLanguageISO(this.availableLanguages[0]);
      LocaleService.setCurrentLanguageISO(this.availableLanguages[0]);
    }

    this.translateService.setDefaultLang(LocaleService.getDefaultLanguageISO());
    this.translateService.setTranslation(
      LocaleService.getCurrentLanguageISO(),
      (translations as any)[LocaleService.getCurrentLanguageISO()],
      true,
    );
    this.translateService.use(LocaleService.getCurrentLanguageISO());

    // set translations for text within layout package
    const layoutTranslation = this.translateService.instant('layout');

    this.insecureRouteService.setLoginBlockTitle(layoutTranslation.loginBlockTitle);
    this.insecureRouteService.setLoginBlockSubTitle(layoutTranslation.loginBlockSubTitle);
    this.insecureRouteService.setForgotPasswordBlockTitle(layoutTranslation.forgotPasswordBlockTitle);
    this.insecureRouteService.setPasswordRestoreBlockTitle(layoutTranslation.passwordRestoreBlockTitle);
    this.insecureRouteService.setUsernamePlaceholder(layoutTranslation.usernamePlaceholder);
    this.insecureRouteService.setPasswordPlaceholder(layoutTranslation.passwordPlaceholder);
    this.insecureRouteService.setPasswordConfirmationPlaceholder(layoutTranslation.passwordConfirmationPlaceholder);
    this.insecureRouteService.setForgotPasswordText(layoutTranslation.forgotPasswordText);
    this.insecureRouteService.setForgotPasswordIcon(layoutTranslation.forgotPasswordIcon);
    this.insecureRouteService.setLoginText(layoutTranslation.loginText);
    this.insecureRouteService.setLoginIcon(layoutTranslation.loginIcon);
    this.insecureRouteService.setBack(layoutTranslation.back);
    this.insecureRouteService.setRequestPassword(layoutTranslation.requestPassword);
    this.insecureRouteService.setPasswordRestore(layoutTranslation.passwordRestore);
    this.insecureRouteService.setShowRegister(false);
  }

  private checkAuthenticationStatus(): Observable<void> {
    return new Observable(observer => {
      // If there's a valid token then quit
      if (!this.authenticationService.isTokenExpired()) {
        observer.next();
        return;
      }

      // If the token is invalid, check if there's a refresh token and try to refresh.
      // doRefreshLogin() will redirect to the redirect state on refresh failure.
      if (this.authenticationService.getRefreshToken()) {
        this.authenticationService.doRefreshLogin().finally(() => {
          observer.next();
        });
        return;
      }

      // The user has a defined state (authenticated or not), continue execution.
      // The transitionService onEnter hook will determine access to the requested route.
      observer.next();
    });
  }

  private setInsecureConfig(): Promise<void> {
    return new Promise<void>(resolve => {
      this.insecureRouteService
        .setForgotPasswordState('forgotPassword')
        .setShowForgotPassword(true)
        .setRegisterState('register')
        .setShowRegister(true)
        .setLogoSrc(`./assets/images/logo.png`);
      resolve();
    });
  }

  private setAuthentication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.authenticationService.setAuthRouter(this.authRouterService);
      this.authenticationService.setRedirectState('login');
      this.authenticationService.setUnauthorizedState('unauthorized');
      this.authenticationService.config.setRootState('dashboard');
      this.authenticationService.config.setCsrfEnabled(false);
      this.authenticationService.config.setNewAuthLogic(false);
      this.authenticationService.config.setClientSideApp(false);
      this.authenticationService.config.setRememberMeEnabled(true);
      this.authenticationService.config.setLoginHttpMethod(RequestMethod.Post);
      this.authenticationService.config.setLoginUsernamePropName('username');
      this.authenticationService.config.setLoginPasswordPropName('password');
      this.authenticationService.config.setLoginUrl(`${environment.apiUrl}/auth/login`);
      this.authenticationService.config.setLogoutHttpMethod(RequestMethod.Post);
      this.authenticationService.config.setLogoutUrl(`${environment.apiUrl}/auth/logout`);
      this.authenticationService.config.setRefreshTokenUrl(`${environment.apiUrl}/auth/refresh-token`);
      this.authenticationService.config.setForgotPasswordUsernamePropName('username');
      this.authenticationService.config.setPasswordRestoreUrl(`${environment.apiUrl}/password/restore`);
      this.authenticationService.config.setForgotPasswordUrl(`${environment.apiUrl}/auth/password-reset`);

      this.authenticationService.config.setForgotPasswordExtraBodyParams({
        redirectUrl: this.router.stateService.href('restorePassword', undefined, { absolute: true }),
      });
      resolve();
    });
  }
}
