export const environment = {
  production: false,
  whiteLabel: {
    number: '49149', // acc
    name: 'MijnMening - Koraal acc',
  },
  isGodBackoffice: false,
  client: 'backoffice',
  apiUrl: 'https://mijnmening-api.ivengi.info',
  baseUrl: 'https://mijnmening-backoffice-koraal-v2.ivengi.info',
  env: 'DEVELOPMENT',
  emailAsUsername: true,
  uuidNamespace: 'e932a1d5-fc70-4cfb-94ca-e0b18c14a31b',
  dateFormat: 'dd-mm-yy'
};
