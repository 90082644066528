import { Notification as BaseNotification, NotificationLevel, NotificationService } from '@ngx-ivengi/notification';
import { CrudAction, Notification as CrudNotification } from '@ngx-ivengi/crud';
import { Injectable, Injector, isDevMode } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PasswordRestoreAttempt } from '@ngx-ivengi/authentication';
import { WebsocketService } from './lib/services/websocket.service';

export interface CustomNotificationRule {
  level?: NotificationLevel;
  action?: CrudAction | CrudAction[];
  crudEntity?: any;
  httpCode?: number;
  useCode?: boolean;
  errors?: {
    code: number;
  }[];
  translateKey?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppNotification {
  private toastrService: ToastrService | undefined;

  private notificationService: NotificationService | undefined;

  private translateService: TranslateService | undefined;

  private websocketService: WebsocketService | undefined;

  constructor(private injector: Injector) {}

  public initialize(): void {
    this.toastrService = this.injector.get(ToastrService);
    this.notificationService = this.injector.get(NotificationService);
    this.translateService = this.injector.get(TranslateService);
    this.websocketService = this.injector.get(WebsocketService);

    this.websocketService.messages.subscribe((wsMessage: string) => {
      this.toastrService?.show(wsMessage, undefined, undefined, this.toastrService.toastrConfig.iconClasses.info);
    });

    this.notificationService.notification().subscribe((notification: BaseNotification) => {
      // if this is not a CrudNotification it must be a custom notification
      if (!(notification instanceof CrudNotification)) {
        this.customNotificationMsg(notification);
        return;
      }

      // check for custom crud notification, or else, display the default notification
      if (!this.customCrudNotificationMsg(notification)) {
        this.translateService?.get(`crudEntity.${notification.getEntityName()}`).subscribe(crudEntity => {
          this.translateService
            ?.get(`notification.${notification.getLevel()}.${notification.getAction()}`, { crudEntity })
            .subscribe((message: any) => {
              if (notification.getLevel() === NotificationLevel.SUCCESS) {
                if (
                  [CrudAction.CREATE, CrudAction.UPDATE, CrudAction.DELETE].indexOf(notification.getAction()) !== -1
                ) {
                  if (notification.getEntityName() !== 'application-draft') {
                    this.showToastr(notification.getLevel(), message);
                  }
                }
              } else {
                switch (notification.getHttpCode()) {
                  case 401: {
                    // do nothing, refresh token or redirect will already handle this
                    break;
                  }
                  case 403: {
                    this.translateService
                      ?.get(`notification.unauthorized.${notification.getAction()}`, { crudEntity })
                      .subscribe(unauthorizedMsg => {
                        this.showToastr(notification.getLevel(), unauthorizedMsg);
                      });
                    break;
                  }

                  default: {
                    this.showToastr(notification.getLevel(), message);
                    break;
                  }
                }
              }
            });
        });
      }
    });
  }

  private customNotificationMsg(notification: BaseNotification): void {
    const data = notification.getData();

    if (data === PasswordRestoreAttempt.FAILED) {
      let translation = this.translateService?.instant(notification.getData());
      const meta = (notification.getMeta && notification.getMeta()) || undefined;

      if (meta && Object.keys(meta).length) {
        const criteria = Object.keys(meta).map(key =>
          this.translateService?.instant(`authentication.passwordrestore.criteria.${key}`, { number: meta[key] }),
        );
        translation += `: ${criteria.join(', ')}`;
      }

      this.showToastr(notification.getLevel(), translation);
      return;
    }

    this.translateService?.get(data).subscribe((translation: string) => {
      // if the translation is not the same as the passed key or the passed key contains spaces,
      // assume the translation succeeded (valid translation keys can't contain spaces
      if (translation !== notification.getData() || /\s/.test(notification.getData())) {
        this.showToastr(notification.getLevel(), translation);
      } else if (isDevMode()) {
        this.toastrService?.show(
          notification.getData(),
          'MISSING TRANSLATION!',
          undefined,
          this.toastrService?.toastrConfig.iconClasses.warning,
        );
      }
    });
  }

  private customCrudNotificationMsg(notification: CrudNotification): boolean {
    const rules: CustomNotificationRule[] = [
      {
        level: NotificationLevel.ERROR,
        action: [CrudAction.CREATE, CrudAction.UPDATE],
        httpCode: 422,
        useCode: true,
      },
      {
        level: NotificationLevel.ERROR,
        action: [CrudAction.DELETE],
        useCode: true,
      },
    ];

    for (const rule of rules) {
      if (rule.level && rule.level !== notification.getLevel()) {
        continue;
      }

      if (rule.action) {
        rule.action = rule.action instanceof Array ? rule.action : [rule.action];
        if (rule.action.indexOf(notification.getAction()) === -1) {
          continue;
        }
      }

      if (rule.crudEntity && rule.crudEntity !== notification.getEntity()) {
        continue;
      }

      if (rule.httpCode && rule.httpCode !== notification.getHttpCode()) {
        continue;
      }

      if (rule.errors && notification.getData() && notification.getData().errors) {
        let specifiedErrorsFound = 0;
        for (let i = 0; i < rule.errors.length; i += 1) {
          for (const key in notification.getData().errors) {
            if (notification.getData().errors.hasOwnProperty(key)) {
              const error = notification.getData().errors[key];

              if (error.msg.code === rule.errors[i].code) {
                specifiedErrorsFound += 1;
              }
            }
          }
        }

        if (specifiedErrorsFound !== rule.errors.length) {
          continue;
        }
      }

      let showedCustomNotification = false;

      if (!rule.translateKey) {
        if (!rule.useCode) {
          continue;
        }

        let counter = 0;
        const data = notification.getData();
        if (typeof data !== 'undefined' && typeof data.errors !== 'undefined') {
          for (const key in data.errors) {
            if (data.errors.hasOwnProperty(key)) {
              const error = data.errors[key];
              const translateKey = `crud.dataError.${error.msg.code}`;
              error.msg.params = error.msg.params || {};
              for (const fieldKey in error.msg.params) {
                if (error.msg.params.hasOwnProperty(fieldKey)) {
                  if (
                    typeof error.msg.params[fieldKey] === 'object' &&
                    error.msg.params[fieldKey].hasOwnProperty(this.translateService?.currentLang)
                  ) {
                    error.msg.params[fieldKey] =
                      error.msg.params[fieldKey][(this.translateService as TranslateService)?.currentLang];
                  }
                }
              }
              const translation = this.translateService?.instant(translateKey, error.msg.params || {});

              // check if translation does exists and if it nog matches the enum value pattern
              const translationExists = translation !== translateKey && /^([A-Z]+_?)+$/.test(translation) === false;
              if (translationExists) {
                setTimeout(() => (this.toastrService as any)[notification.getLevel()](translation), counter * 1000);
                showedCustomNotification = true;
                counter += 1;
              }
            }
          }
        }
      } else {
        // Still here? Show custom message!
        this.translateService?.get(rule.translateKey).subscribe((translation: string) => {
          this.showToastr(notification.getLevel(), translation);
        });
        showedCustomNotification = true;
      }

      return showedCustomNotification;
    }

    return false;
  }

  private showToastr(notificationLevel: NotificationLevel, message: string): void {
    const toastrMapping = {
      [NotificationLevel.SUCCESS]: this.toastrService?.toastrConfig.iconClasses.success,
      [NotificationLevel.INFO]: this.toastrService?.toastrConfig.iconClasses.info,
      [NotificationLevel.WARNING]: this.toastrService?.toastrConfig.iconClasses.warning,
      [NotificationLevel.ERROR]: this.toastrService?.toastrConfig.iconClasses.error,
    };

    this.toastrService?.show(
      message,
      undefined,
      undefined,
      toastrMapping[notificationLevel] || this.toastrService?.toastrConfig.iconClasses.info,
    );
  }
}
