import { Setting } from '../setting.model';
import { SettingTypes } from '../../../components/shared/shared.enum';

export class I18nSettings extends Setting {
  public type = SettingTypes.LANGUAGES;
  public languages = ['nl-NL'];

  public setLanguages(languages: string[]): this {
    if (Array.isArray(languages)) {
      this.languages = languages.filter(language => typeof language === 'string');
    }
    return this;
  }

  public fromResponse(response: any): this {
    this.setLanguages(response.languages);
    return this;
  }
}
