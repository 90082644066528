import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { Dialog } from 'primeng/dialog';
import { AuthenticationService } from '@ngx-ivengi/authentication';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ngx-ivengi/notification';
import { Title } from '@angular/platform-browser';
import { HeaderService } from '@ngx-ivengi/layout';
// eslint-disable-next-line import/extensions
import 'moment/locale/nl.js';
import { startWith } from 'rxjs/operators';
import { LocaleService } from '@ngx-ivengi/locale';
import { StateService } from '@uirouter/angular';
import { ApplicationUser } from './lib/models/application-user.model';
import { WebsocketService } from './lib/services/websocket.service';
import { environment } from '../whitelabel/environment';
import { AppConfig } from './app.config';
import { fadeAnimation } from './lib/animation-fade';
import { DataStoreService } from './lib/services/data-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  public isConfigured = false;
  public lastState = '';
  public showNoConnectionDialog = false;
  public bodyClasses = '';
  private unauthenticatedBodyClasses = 'background-image';
  private wsConnectedSubscription: Subscription | undefined;
  private noWSConnectionTimeout: any;
  public profile: ApplicationUser | undefined;
  private subscriptions = new Subscription();

  @ViewChild('noConnectionDialog', { static: true }) private noConnectionDialog: Dialog | undefined;

  constructor(
    private cdRef: ChangeDetectorRef,
    private appConfig: AppConfig,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    public transitionService: TransitionService,
    private titleService: Title,
    private headerService: HeaderService,
    private elementRef: ElementRef,
    private localeService: LocaleService,
    private websocketService: WebsocketService,
    private stateService: StateService,
    public dataStoreService: DataStoreService,
  ) {}

  ngOnInit(): void {
    this.appConfig.isConfigured.subscribe(isConfigured => {
      this.isConfigured = isConfigured;
      this.cdRef.detectChanges();
    });

    this.transitionService.onEnter({}, (transition, state) => {
      switch (state.name) {
        case 'servicedesk': {
          this.stateService.go(this.lastState, transition?.router?.stateService?.params);
          break;
        }
        default: {
          if (state.name !== this.lastState && state.name !== '') {
            this.lastState = state.name as string;
          }
          break;
        }
      }
    });
    this.titleService.setTitle(environment.whiteLabel.name);

    this.subscriptions.add(
      this.authService.authenticationStatus().subscribe((isAuthenticated: boolean) => {
        this.bodyClasses = isAuthenticated ? '' : this.unauthenticatedBodyClasses;
        this.headerService.resizeHeaderOnScroll(this.elementRef.nativeElement);
        if (isAuthenticated) {
          if (!(this.wsConnectedSubscription instanceof Subscription)) {
            this.wsConnectedSubscription = this.checkConnectionStatus();
          }
        } else {
          if (this.wsConnectedSubscription instanceof Subscription) {
            this.wsConnectedSubscription.unsubscribe();
          }
          if (typeof this.noWSConnectionTimeout !== 'undefined') {
            clearTimeout(this.noWSConnectionTimeout);
          }
        }
      }),
    );

    this.subscriptions.add(
      this.authService
        .authenticationStatus()
        .pipe(startWith(this.authService.isAuthenticated()))
        .subscribe((isAuthenticated: boolean) => {
          if (isAuthenticated) {
            this.dataStoreService.fetchProfile();
          }
          this.onAuthenticationStatusUpdate(isAuthenticated);
        }),
    );

    this.subscriptions.add(
      this.dataStoreService.getProfile().subscribe((profile: any | undefined) => {
        if (profile === null) {
          return;
        }
        this.profile = profile;
      }),
    );

    this.cdRef.detectChanges();
  }

  private onAuthenticationStatusUpdate(isAuthenticated: any): void {
    if (!isAuthenticated) {
      this.profile = undefined;
      this.dataStoreService.clearProfile();
    }
  }

  private checkConnectionStatus(): Subscription {
    return this.websocketService.isConnected.subscribe((isConnected: boolean) => {
      if (typeof this.noWSConnectionTimeout !== 'undefined') {
        clearTimeout(this.noWSConnectionTimeout);
      }
      if (isConnected) {
        this.showNoConnectionDialog = false;
      } else {
        this.noWSConnectionTimeout = setTimeout(() => {
          this.showNoConnectionDialog = true;
        }, 15000);
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
