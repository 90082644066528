import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, publishReplay, refCount } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { ApplicationUser } from '../models/application-user.model';

@Injectable({
  providedIn: 'root',
})
export class DataStoreService {
  // STORES
  private profile$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private profileService: ProfileService) {}

  // ACTIONS
  public getProfile = (): Observable<any> => this.profile$.asObservable();

  public fetchProfile = (): void => this.fetchProfileHandler();

  public clearProfile = (): void => this.profile$.next(null);

  // HELPERS
  private fetchProfileHandler(): void {
    this.profileService
      .getProfile()
      .pipe(
        catchError((): any => {
          return this.profile$.next([]);
        }),
        publishReplay(1),
        refCount(),
      )
      .subscribe((data: any) => {
        const profile = new ApplicationUser();
        profile.fromResponse(data);
        this.profile$.next(profile);
      });
  }
}
