import { Ng2DynamicStateDeclaration } from '@ngx-ivengi/locale';
import { ForgotPasswordComponent, LoginComponent, LogoutComponent, PasswordRestoreComponent } from '@ngx-ivengi/layout';
import { LoginAuthorizationCodeComponent } from './components/login/login-authorization-code.component';
// import { SsoUnauthorizedComponent } from './pages/sso-unauthorized/sso-unauthorized.component';
// import { UnexpectedErrorComponent } from './pages/unexpected-error/unexpected-error.component';

const loginState = {
  name: 'login',
  future: true,
  component: LoginComponent,
  data: { insecure: true },
};

// Login force state reachable for forcing to display login box even when SSO is enabled.
// Handy for God users which aren't listed in the authority
const loginForceState = {
  name: 'loginForce',
  future: true,
  component: LoginComponent,
  data: { insecure: true },
};

const loginAuthorizationCodeState = {
  name: 'loginAuthorizationCode',
  future: true,
  component: LoginAuthorizationCodeComponent,
  data: { insecure: true },
}

const forgotPasswordState = {
  name: 'forgotPassword',
  future: true,
  component: ForgotPasswordComponent,
  data: { insecure: true },
};

const restorePasswordState = {
  name: 'restorePassword',
  future: true,
  component: PasswordRestoreComponent,
  data: { insecure: true },
};

const logoutState = {
  name: 'logout',
  future: true,
  component: LogoutComponent,
};

// const ssoUnauthorizedState = {
//   name: 'ssoUnauthorized',
//   future: true,
//   component: SsoUnauthorizedComponent,
//   data: { insecure: true },
// };
//
// const unexpectedErrorState = {
//   name: 'unexpectedError',
//   future: true,
//   component: UnexpectedErrorComponent,
//   data: { insecure: true },
// };
//
const profileFutureState = {
  name: 'profile.**',
  future: true,
  loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
};

const dashboardFutureState = {
  name: 'dashboard.**',
  future: true,
  loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
};

const questionCategoryFutureState = {
  name: 'question-category.**',
  future: true,
  loadChildren: () => import('./pages/question-category/question-category.module').then(m => m.QuestionCategoryModule),
};

const questionnaireFutureState = {
  name: 'questionnaire.**',
  future: true,
  loadChildren: () => import('./pages/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
};

const planningFutureState = {
  name: 'planning.**',
  future: true,
  loadChildren: () => import('./pages/planning/planning.module').then(m => m.PlanningModule),
};

const clientFutureState = {
  name: 'client.**',
  future: true,
  loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule),
};

const employeeFutureState = {
  name: 'employee.**',
  future: true,
  loadChildren: () => import('./pages/employee/employee.module').then(m => m.EmployeeModule),
};

const costCenterFutureState = {
  name: 'cost-center.**',
  future: true,
  loadChildren: () => import('./pages/cost-center/cost-center.module').then(m => m.CostCenterModule),
};

const locationFutureState = {
  name: 'location.**',
  future: true,
  loadChildren: () => import('./pages/location/location.module').then(m => m.LocationModule),
};

const whiteLabelFutureState = {
  name: 'white-label.**',
  future: true,
  loadChildren: () => import('./pages/white-label/white-label.module').then(m => m.WhiteLabelModule),
};

export const APP_STATES: Ng2DynamicStateDeclaration[] = [
  loginState,
  loginForceState,
  loginAuthorizationCodeState,
  forgotPasswordState,
  restorePasswordState,
  logoutState,
  profileFutureState,
  dashboardFutureState,
  questionCategoryFutureState,
  questionnaireFutureState,
  planningFutureState,
  clientFutureState,
  employeeFutureState,
  costCenterFutureState,
  locationFutureState,
  whiteLabelFutureState,
];
