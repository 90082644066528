<div *ngIf="isConfigured" [ngClass]='bodyClasses'>

  <ngx-layout-header>
    <ngx-layout-header-logo
      src="/assets/images/logo.png"
      srcSmall="/assets/images/logo_small.png"
      [fullImage]="true"
      state="dashboard"
    ></ngx-layout-header-logo>
    <ngx-layout-header-not-nav>
      <ngx-layout-header-profile
        *ngIf="profile"
        [avatar]="''"
        [name]="profile ? profile.getFullName() : ''"
      ></ngx-layout-header-profile>
    </ngx-layout-header-not-nav>
  </ngx-layout-header>

  <ngx-layout-wrapper>
    <ngx-layout-sidebar>
      <ngx-layout-sidebar-nav></ngx-layout-sidebar-nav>
    </ngx-layout-sidebar>
    <ngx-layout-content>
      <ngx-layout-page-head></ngx-layout-page-head>
      <div [@fadeAnimation]="lastState">
        <div>
          <ui-view></ui-view>
        </div>
      </div>
    </ngx-layout-content>
  </ngx-layout-wrapper>

</div>
