import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { RequestParams } from '@ngx-ivengi/crud';
import { environment } from '../../whitelabel/environment';

export class Util {
  /**
   * retrieve the values / keys of a enum as an array.
   * @param enums enum provided
   * @param value true if you want values, false if you want keys
   * @param numeric set true if u want response that are numeric to return as integers instead of strings and filter out the rest
   */
  public static enumToArray(enums: any, value = true, numeric = false): string[] {
    let values = Object.keys(enums).map(k => (value ? enums[k as any] : k));
    if (numeric) {
      values = values
        .filter((item: any) => {
          return !Number.isNaN(parseInt(item, 10));
        })
        .map((item: any) => {
          return parseInt(item, 10);
        });
    }
    return values;
  }

  public static isHourString(value: any): boolean {
    return /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(value.toString());
  }

  public static camelCaseToKebabCase(value: string): string {
    return value
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/\s+/g, '-')
      .toLowerCase();
  }

  public static kebabCaseToCamelCase(value: string): string {
    return value.replace(/(-\w)/g, part => part[1].toUpperCase());
  }

  public static toCurrency(amount: number): string {
    return Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount);
  }

  public static findPathOfPropWithCondition(originalObject: any, condition: any): any[] | undefined {
    const objectsAlreadyChecked: any[] = [];
    const path = [];
    const results = [];
    if (!originalObject && (typeof originalObject !== 'object' || Array.isArray(originalObject))) {
      return;
    }
    if (typeof condition !== 'function') {
      return;
    }
    (function find(object): any {
      for (const key of Object.keys(object)) {
        if (condition(key, path, object) === true) {
          path.push(key);
          results.push(path.join('.'));
          path.pop();
        }
        const currentProperty = object[key];
        if (currentProperty && typeof currentProperty === 'object' && !Array.isArray(currentProperty)) {
          if (!objectsAlreadyChecked.find(obj => obj === currentProperty)) {
            path.push(key);
            objectsAlreadyChecked.push(currentProperty);
            find(currentProperty);
            path.pop();
          }
        }
      }
    })(originalObject);
    // eslint-disable-next-line consistent-return
    return results;
  }

  public static getSaveRequestParams(entity: any): { data: any; headers: HttpHeaders } {
    const headers = new HttpHeaders();
    let data = new FormData();
    const payload = {};
    const entityToRequest = entity.toRequest();
    // find the path to the FileList objects.
    const pathsToFileListObjects =
      Util.findPathOfPropWithCondition(
        entityToRequest,
        (key: any, path: any, obj: any) => obj[key] instanceof FileList,
      ) || [];
    if (pathsToFileListObjects.length < 1) {
      data = entityToRequest;
    } else {
      // Add the files to FormData and remove them from the entity object.
      for (let i = 0; i < pathsToFileListObjects.length; i += 1) {
        const currentPath = pathsToFileListObjects[i].split('.');
        const fileListObject = currentPath.reduce((res: any, prop: any) => res[prop], entityToRequest);
        _.unset(entityToRequest, currentPath);
        for (let j = 0; j < fileListObject.length; j += 1) {
          data.append(pathsToFileListObjects[i], fileListObject[j], fileListObject[j].name);
        }
      }
      // add the other properties to the payload (non-files).
      for (const key of Object.keys(entityToRequest)) {
        // data.append(key, entity[key]); // no need to also append them seperately. Will all be added to the payload.
        (payload as any)[key] = entityToRequest[key];
      }
      data.append('payload', JSON.stringify(payload));
    }
    return {
      data,
      headers,
    };
  }

  public static getDownloadParams(requestParams: RequestParams): HttpParams {
    let httpParams = new HttpParams();
    if (requestParams) {
      const params = requestParams.toKeyValueParams();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      params.$top = 1000; // max for exports
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete params.$skip; // remove skip, not needed for exports
      for (const key in params) {
        if (params[key]) {
          httpParams = httpParams.append(key, params[key]);
        }
      }
    }
    return httpParams;
  }

  public static getUUID(): string {
    return uuidv5(uuidv4(), environment.uuidNamespace);
  }

  public static getFilenameFromContentDispositionHeader(response: HttpResponse<any>): string {
    const contentDispositionHeader = response.headers.get('content-disposition');
    if (contentDispositionHeader) {
      const matches = contentDispositionHeader.match(/filename=([^;]+)/i);
      if (matches?.[1]) {
        return matches[1];
      }
    }
    throw new Error('Unable to determine filename from content-disposition header');
  }
}
