import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@ngx-ivengi/authentication';
import { CrudConfig } from '@ngx-ivengi/crud';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as fibonacci from 'fibonacci';
import { environment } from '../../../whitelabel/environment';
import { WebsocketMessageTypes } from '../../components/shared/shared.enum';
import { IWebsocketMessage } from '../interfaces/websocket-message';

@Injectable({ providedIn: 'root' })
export class WebsocketService {
  private authenticated = false;
  private reconnectAttempt = 0;
  private ws: WebSocket | null = null;
  private socketId: number | undefined;
  private connectInProgress = false;
  private connectionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private messagesSubject: Subject<any> = new Subject<any>();
  public messages: Observable<any> = this.messagesSubject.asObservable();
  private downloadsSubject: Subject<any> = new Subject<any>();
  public downloads: Observable<any> = this.downloadsSubject.asObservable();
  private updatesSubject: Subject<{ type: string; id: string }> = new Subject<any>();
  public updates: Observable<{ type: string; id: string }> = this.updatesSubject.asObservable();
  private notificationsSubject: Subject<{ id: string }> = new Subject<any>();
  public notifications: Observable<{ id: string }> = this.notificationsSubject.asObservable();
  public isConnected: Observable<boolean> = this.connectionStatus.asObservable();

  constructor(
    protected crudConfig: CrudConfig,
    protected http: HttpClient,
    protected authService: AuthenticationService,
  ) {
    this.authService.authenticationStatus().subscribe((isAuthenticated: boolean) => {
      this.authenticated = isAuthenticated;
      if (isAuthenticated) {
        this.connect().subscribe();
      } else {
        this.connectionStatus.next(false);
      }
    });

    this.isConnected.subscribe((isConnected: boolean) => {
      if (!isConnected && this.ws) {
        // close after 1 second to make last logout calls possible
        setTimeout(() => {
          if (this.ws) {
            this.ws.close();
            this.ws = null;
          }
        }, 1000);
      }
    });
  }

  private reconnect(): void {
    this.reconnectAttempt += 1;
    const timeout: number = fibonacci.iterate(this.reconnectAttempt).number * 1000;

    setTimeout(() => {
      const onConnectSubscription = this.connect().subscribe(
        () => {
          if (!this.ws || ([WebSocket.CONNECTING, WebSocket.OPEN] as number[]).indexOf(this.ws.readyState) === -1) {
            this.reconnect();
          }
          if (onConnectSubscription) {
            onConnectSubscription.unsubscribe();
          }
        },
        () => {
          this.reconnect();
        },
      );
    }, timeout);
  }

  public connect(): Observable<void> {
    if (this.connectInProgress) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return new BehaviorSubject<void>(null).asObservable();
    }

    this.connectInProgress = true;
    return new Observable<void>(observer => {
      if (!this.ws || this.ws.readyState === WebSocket.CLOSED) {
        const tokenSubscription = this.getAuthenticationToken().subscribe(
          (response: any) => {
            // : { token: string }) => {
            const websocketBaseUrl = `${this.crudConfig.getApiUrl()?.replace(/^http(.*)/, 'ws$1')}`;
            const websocketQueryString = `?token=${response.token}&organization=${environment.whiteLabel.number}`;
            this.ws = new WebSocket(`${websocketBaseUrl}${websocketQueryString}`);

            this.ws.addEventListener('open', () => {
              this.reconnectAttempt = 0;
              this.connectionStatus.next(true);
            });

            this.ws.addEventListener('close', () => {
              this.connectionStatus.next(false);

              if (this.authenticated) {
                console.log('WS closed. Try to reconnect');
                this.reconnect();
              }
            });

            this.ws.addEventListener('error', () => {
              this.connectionStatus.next(false);
            });

            this.ws.addEventListener('message', async (msg: any) => {
              await this.handleMessage(msg);
            });

            this.connectInProgress = false;
            tokenSubscription.unsubscribe();
            observer.complete();
          },
          err => {
            this.connectInProgress = false;
            observer.error(err);
            observer.complete();
          },
        );
      } else {
        this.connectInProgress = false;
        observer.complete();
      }
    });
  }

  public disconnect(): Observable<void> {
    return new Observable<void>(observer => {
      // If no active socket is available, emit immediate success
      if (!this.ws) {
        observer.next();
        observer.complete();
        return;
      }

      // Close when open(ing)
      const openStates: number[] = [WebSocket.CONNECTING, WebSocket.OPEN];
      if (this.ws && openStates.indexOf(this.ws.readyState) !== -1) {
        this.ws.close();
        this.isConnected.subscribe((isConnected: boolean) => {
          if (!isConnected) {
            observer.next();
            observer.complete();
          }
        });
      }

      // Emit when already close(d)(ing)
      const closedStates: number[] = [WebSocket.CLOSING, WebSocket.CLOSED];
      if (this.ws && closedStates.indexOf(this.ws.readyState) !== -1) {
        this.isConnected.subscribe((isConnected: boolean) => {
          if (!isConnected) {
            observer.next();
            observer.complete();
          }
        });
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private getAuthenticationToken(): Observable<Object> {
    return this.http.get(`${this.crudConfig.getApiUrl()}/authentication/ws-token`, {
      headers: new HttpHeaders(this.authService.getAuthorizationHeader()),
    });
  }

  private async handleMessage(response: any): Promise<void> {
    if (response.data instanceof Blob) {
      // await this.downloadsService.processBlob(response.data);
    } else {
      const msg = JSON.parse(response.data);

      switch (msg.type) {
        case WebsocketMessageTypes.PING: {
          if (msg.data && msg.data.needsAuthRefresh) {
            await this.authService.doRefreshLogin();
          }

          if (this.ws?.readyState === WebSocket.OPEN) {
            const message: IWebsocketMessage = {
              type: WebsocketMessageTypes.PONG,
              data: this.authService.getAccessToken(),
            };

            this.ws.send(JSON.stringify(message));
          }
          break;
        }

        case WebsocketMessageTypes.ID: {
          if (msg.data && msg.data.id) {
            this.socketId = msg.data.id;
          }
          break;
        }

        case WebsocketMessageTypes.DOWNLOAD: {
          if (msg.data.status === 'pending') {
            this.downloadsSubject.next(msg.data);
          } else {
            this.messagesSubject.next(msg.data);
          }
          break;
        }

        case WebsocketMessageTypes.UPDATE: {
          this.updatesSubject.next(msg.data);
          break;
        }

        case WebsocketMessageTypes.NOTIFICATION: {
          this.notificationsSubject.next(msg.data);
          break;
        }

        case WebsocketMessageTypes.MESSAGE: {
          this.messagesSubject.next(msg.data);
          break;
        }

        case WebsocketMessageTypes.LOGOUT: {
          this.authService.logout();
          break;
        }
        default:
          break;
      }
    }
  }

  public hasSocket(): boolean {
    return !!this.ws;
  }

  public getSocket(): WebSocket | null {
    return this.ws;
  }

  public getSocketId(): number | undefined {
    return this.socketId;
  }

  public send(data: { [key: string]: any; type: string }): void {
    if (this.hasSocket()) {
      this.ws?.send(JSON.stringify(data));
    } else {
      throw new Error('no active websocket to send message');
    }
  }
}
