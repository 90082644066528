import { CrudModel } from '@ngx-ivengi/crud';
import * as _ from 'lodash';
import { AccountEntityTypes, ApplicationUserRoles } from 'src/app/components/shared/shared.enum';
import { environment } from '../../../whitelabel/environment';
import { Util } from '../util';

export class ApplicationUser extends CrudModel {
  public enabled = true;
  public firstName: string | undefined;
  public middleName: string | undefined;
  public lastName: string | undefined;
  public email: string | undefined;
  public username: string | undefined;
  public type: AccountEntityTypes | undefined;
  public phoneNumber: string | undefined;
  public role: ApplicationUserRoles | undefined;
  public password: string | undefined;
  public passwordConfirm: string | undefined;

  public setEnabled(enabled: boolean): this {
    if (typeof enabled === 'boolean') {
      this.enabled = enabled;
    }
    return this;
  }

  public setFirstName(firstName: string): this {
    if (typeof firstName === 'string') {
      this.firstName = firstName;
    }
    return this;
  }

  public setMiddleName(middleName: string): this {
    if (typeof middleName === 'string') {
      this.middleName = middleName;
    }
    return this;
  }

  public setLastName(lastName: string): this {
    if (typeof lastName === 'string') {
      this.lastName = lastName;
    }
    return this;
  }

  public getFullName(): string {
    return [this.firstName, this.middleName, this.lastName]
      .filter(part => !!part)
      .map(part => (part as string).trim())
      .filter(part => !!part)
      .join(' ');
  }

  public setEmail(email: string): this {
    if (typeof email === 'string') {
      this.email = email;
    }
    return this;
  }

  public setUsername(username: string): this {
    if (typeof username === 'string') {
      this.username = username;
    }
    return this;
  }

  public setType(type: AccountEntityTypes): this {
    if (typeof type === 'string' && Util.enumToArray(AccountEntityTypes).includes(type)) {
      this.type = type;
    }
    return this;
  }

  public setPhoneNumber(phoneNumber: string): this {
    if (typeof phoneNumber === 'string') {
      this.phoneNumber = phoneNumber;
    }
    return this;
  }

  public setUserRole(role: ApplicationUserRoles): this {
    if (typeof role === 'string') {
      this.role = role;
    }
    return this;
  }

  public fromResponse(response: any): this {
    if (response) {
      this.setId(response._id)
        .setEnabled(response.enabled)
        .setType(response.type)
        .setPhoneNumber(response.phoneNumber)
        .setUserRole(response.role)
        .setFirstName(response.firstName)
        .setMiddleName(response.middleName)
        .setLastName(response.lastName)
        .setEmail(response.email)
        .setUsername(response.username);
    }
    return this;
  }

  public override toRequest(): any {
    const data: any = _.cloneDeep(this);
    data.redirectUrl = `${environment.baseUrl}/wachtwoord-instellen`;

    if (environment.emailAsUsername && this.type !== AccountEntityTypes.God) {
      data.username = data.email;
    }

    return data;
  }
}
