import { CrudModel } from '@ngx-ivengi/crud';
import { UserRoleEnum } from 'src/app/components/shared/shared.enum';
import { Location } from './location.model';
import { CostCenter } from './cost-center.model';

export class User extends CrudModel {
  public username!: string;
  public password!: string;
  public get fullName() {
    return [
      this.firstName,
      this.middleName,
      this.lastName,
    ].filter(val => !!val)
      .join(' ');
  };
  public firstName!: string;
  public middleName!: string;
  public lastName!: string;
  public dateOfBirth!: Date;
  public startDate!: Date;
  public endDate!: Date;
  public gender!: string;
  public phoneNumber!: string;
  public email!: string;
  public god!: boolean;
  public administrator!: boolean;
  public locationManager!: boolean;
  public teamLeader!: boolean;
  public mentor!: boolean;
  public client!: boolean;
  public role!: string;
  public status!: string;
  public mobileAppUser!: boolean;
  public clientNumber!: string;
  public employeeNumber!: string;
  public costCenterId!: string[];
  public costCenter!: CostCenter[];
  public locationId: string[] = [];
  public location?: Location[];

  public setUserName(userName: string): this {
    if(typeof userName === 'string') {
      this.username = userName;
    }
    return this;
  }

  public setPassword(password: string): this {
    if(typeof password === 'string') {
      this.password = password;
    }
    return this;
  }

  public setFirstName(firstName: string): this {
    if(typeof firstName === 'string') {
      this.firstName = firstName;
    }
    return this;
  }

  public setMiddleName(middleName: string): this {
    if(typeof middleName === 'string') {
      this.middleName = middleName;
    }
    return this;
  }

  public setLastName(lastName: string): this {
    if(typeof lastName === 'string') {
      this.lastName = lastName;
    }
    return this;
  }

  public setGender(gender: string): this {
    if(typeof gender === 'string') {
      this.gender = gender;
    }
    return this;
  }

  public setDateOfBirth(date: string | Date): this {
    if (typeof date === 'string') {
      this.dateOfBirth = new Date(date);
    } else if (date instanceof Date) {
      this.dateOfBirth = date;
    }
    return this;
  }

  public setStartDate(date: string | Date): this {
    if (typeof date === 'string') {
      this.startDate = new Date(date);
    } else if (date instanceof Date) {
      this.startDate = date;
    }
    return this;
  }

  public setEndDate(date: string | Date): this {
    if (typeof date === 'string') {
      this.endDate = new Date(date);
    } else if (date instanceof Date) {
      this.endDate = date;
    }
    return this;
  }

  public setPhoneNr(phoneNr: string): this {
    if(typeof phoneNr === 'string') {
      this.phoneNumber = phoneNr;
    }
    return this;
  }

  public setEmail(email: string): this {
    if(typeof email === 'string') {
      this.email = email;
    }
    return this;
  }

  public setClientNumber(clientNumber: string): this {
    if(typeof clientNumber === 'string') {
      this.clientNumber = clientNumber;
    }
    return this;
  }

  public setEmployeeNumber(employeeNumber: string): this {
    if(typeof employeeNumber === 'string') {
      this.employeeNumber = employeeNumber;
    }
    return this;
  }

  public setStatus(status: string): this {
    if(typeof status === 'string') {
      this.status = status;
    }
    return this;
  }

  public setMobileAppUser(mobileAppUser: boolean): this {
    if(typeof mobileAppUser === 'boolean') {
      this.mobileAppUser = mobileAppUser;
    }
    return this;
  }

  public setGod(isGod: boolean): this {
    if(typeof isGod === 'boolean') {
      this.god = isGod;
    }
    return this;
  }

  public setAdministrator(isAdministrator: boolean): this {
    if(typeof isAdministrator === 'boolean') {
      this.administrator = isAdministrator;
    }
    return this;
  }

  public isAdministrator(): boolean {
    return this.administrator;
  }

  public setLocationManager(isLocationManager: boolean): this {
    if(typeof isLocationManager === 'boolean') {
      this.locationManager = isLocationManager;
    }
    return this;
  }

  public isLocationManager(): boolean {
    return this.locationManager;
  }

  public setTeamLeader(isTeamLeader: boolean): this {
    if(typeof isTeamLeader === 'boolean') {
      this.teamLeader = isTeamLeader;
    }
    return this;
  }

  public setMentor(isMentor: boolean): this {
    if(typeof isMentor === 'boolean') {
      this.mentor = isMentor;
    }
    return this;
  }

  public setClient(isClient: boolean): this {
    if(typeof isClient === 'boolean') {
      this.client = isClient;
    }
    return this;
  }

  public setRole(role: string): this {
    if(typeof role === 'string') {
      this.role = role;
    }
    return this;
  }

  public setCostCenterId(ids: string[]): this {
    this.costCenterId = ids;
    return this;
  }

  public setCostCenter(costcenter: CostCenter[]): this {
    this.costCenter = costcenter;
    return this;
  }

  public setLocationId(ids: string[]): this {
    this.locationId = ids;
    return this;
  }

  public setLocation(location: Location[]): this {
    this.location = location;
    return this;
  }

  public override toRequest(): object {
    return JSON.parse(JSON.stringify(this));
  }

  public fromResponse(response: any): this {
    this
      .setId(response._id)
      .setUserName(response.username)
      .setFirstName(response.firstName)
      .setMiddleName(response.middleName || '')
      .setLastName(response.lastName)
      .setGender(response.gender)
      .setUserName(response.username || '')
      .setPhoneNr(response.phoneNr)
      .setEmail(response.email)
      .setDateOfBirth(response.dateOfBirth)
      .setStartDate(response.startDate)
      .setEndDate(response.endDate)
      .setStatus(response.status)
      .setPhoneNr(response.phoneNumber)
      .setRole(response.role)
      .setMobileAppUser(response.mobileAppUser || false)
      .setClientNumber(response.clientNumber)
      .setEmployeeNumber(response.employeeNumber)
      .setCostCenterId(response.costCenterId || [])
      .setCostCenter(response.costCenter || [])
      .setGod(response.role === UserRoleEnum.GOD)
      .setAdministrator(response.role === UserRoleEnum.ADMINISTRATOR)
      .setLocationManager(response.role === UserRoleEnum.LOCATION_MANAGER)
      .setTeamLeader(response.role === UserRoleEnum.TEAM_LEADER)
      .setMentor(response.role === UserRoleEnum.MENTOR)
      .setClient(response.role === UserRoleEnum.CLIENT)
      .setLocationId(response.locationId || [])
      .setLocation(response.location || []);

    return this;
  }
}
