export const routes = [
  {
    state: 'unauthorized',
    url: '/geen-toegang',
  },
  {
    state: 'login',
    url: '/inloggen',
  },
  {
    state: 'loginForce',
    url: '/login/force',
  },
  {
    state: 'loginAuthorizationCodeState',
    url: '/login-authorization-code?{authorization_code}'
  },
  {
    state: 'logout',
    url: '/uitloggen',
  },
  {
    state: 'restorePassword',
    url: '/wachtwoord-instellen?{token}',
  },
  {
    state: 'forgotPassword',
    url: '/wachtwoord-vergeten',
  },
  {
    state: 'ssoUnauthorized',
    url: '/sso-unauthorized',
  },
  {
    state: 'unexpectedError',
    url: '/unexpected-error',
  },
  {
    state: 'profile',
    url: '/profiel'
  },
  {
    state: 'profile.account',
    url: '/account'
  },
  {
    state: 'dashboard',
    url: '/dashboard',
  },
  {
    state: 'question-category',
    url: '/vraagcategorieen',
  },
  {
    state: 'question-category.overview',
    url: '/overzicht?{page}{title}',
  },
  {
    state: 'question-category.add',
    url: '/toevoegen',
  },
  {
    state: 'question-category.edit',
    url: '/{questionCategoryId}',
  },
  {
    state: 'questionnaire',
    url: '/vragenlijsten',
  },
  {
    state: 'questionnaire.overview',
    url: '/overzicht?{page}{title}{showArchive}{locations}',
  },
  {
    state: 'questionnaire.add',
    url: '/toevoegen'
  },
  {
    state: 'questionnaire.edit',
    url: '/{questionnaireId}'
  },
  {
    state: 'questionnaire.edit.question',
    url: '/vragen'
  },
  {
    state: 'questionnaire.edit.question.overview',
    url: '/overzicht?{page}{title}'
  },
  {
    state: 'questionnaire.edit.question.edit',
    url: '/{questionId}'
  },
  {
    state: 'planning',
    url: '/metingen',
  },
  {
    state: 'planning.overview',
    url: '/overzicht?{page}{title}',
  },
  {
    state: 'planning.add',
    url: '/toevoegen',
  },
  {
    state: 'planning.edit',
    url: '/{planningId}',
  },
  {
    state: 'planning.edit.planning-iteration',
    url: '/iteraties',
  },
  {
    state: 'planning.edit.planning-iteration.overview',
    url: '/overzicht?{page}',
  },
  {
    state: 'planning.edit.planning-iteration.detail',
    url: '/{planningIterationId}?{page}',
  },
  {
    state: 'planning.edit.planning-iteration.detail.survey',
    url: '/survey',
  },
  {
    state: 'planning.edit.planning-iteration.detail.survey.detail',
    url: '/{surveyId}',
  },
  {
    state: 'client',
    url: '/clienten',
  },
  {
    state: 'client.overview',
    url: '/overzicht?{page}{name}',
  },
  {
    state: 'client.add',
    url: '/toevoegen',
  },
  {
    state: 'client.detail',
    url: '/{userId}',
  },
  {
    state: 'client.detail.personal',
    url: '/persoonsgegevens',
  },
  {
    state: 'client.detail.mijn-mening',
    url: '/mijn-mening',
  },
  {
    state: 'client.detail.account',
    url: '/account',
  },
  {
    state: 'client.detail.cost-center',
    url: '/kostenplaatsen',
  },
  {
    state: 'client.detail.location',
    url: '/locaties',
  },
  {
    state: 'employee',
    url: '/medewerkers',
  },
  {
    state: 'employee.overview',
    url: '/overzicht?{page}{name}',
  },
  {
    state: 'employee.add',
    url: '/toevoegen',
  },
  {
    state: 'employee.detail',
    url: '/{userId}',
  },
  {
    state: 'employee.detail.personal',
    url: '/persoonsgegevens',
  },
  {
    state: 'employee.detail.mijn-mening',
    url: '/mijn-mening',
  },
  {
    state: 'employee.detail.account',
    url: '/account',
  },
  {
    state: 'employee.detail.cost-center',
    url: '/kostenplaatsen',
  },
  {
    state: 'employee.detail.location',
    url: '/locaties',
  },
  {
    state: 'cost-center',
    url: '/kostenplaatsen',
  },
  {
    state: 'cost-center.overview',
    url: '/overzicht?{page}{nameAndId}',
  },
  {
    state: 'cost-center.add',
    url: '/toevoegen',
  },
  {
    state: 'cost-center.edit',
    url: '/{costCenterId}',
  },
  {
    state: 'location',
    url: '/locaties',
  },
  {
    state: 'location.add',
    url: '/toevoegen',
  },
  {
    state: 'location.edit',
    url: '/{locationId}',
  },
  {
    state: 'location.overview',
    url: '/overzicht?{page}{nameAndId}',
  },
  {
    state: 'white-label',
    url: '/white-labels',
  },
  {
    state: 'white-label.overview',
    url: '/overzicht?{page}{name}',
  },
  {
    state: 'white-label.add',
    url: '/toevoegen',
  },
  {
    state: 'white-label.edit',
    url: '/{whiteLabelId}',
  },
];
