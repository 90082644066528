import { Component, OnInit } from '@angular/core';
import { AuthenticationService, AuthorizationCodeLoginRequest } from '@ngx-ivengi/authentication';
import { HttpClient } from '@angular/common/http';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { CrudConfig } from '@ngx-ivengi/crud';

@Component({
  template: '',
})
export class LoginAuthorizationCodeComponent implements OnInit {
  public constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private router: UIRouter,
    private crudConfig: CrudConfig,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
  }

  public ngOnInit(): void {
    if (!this.uiRouterGlobals.params['authorization_code']) {
      this.router.stateService.go(this.authService.getRedirectState());
    } else {
      const loginRequest = new AuthorizationCodeLoginRequest(this.uiRouterGlobals.params['authorization_code']);
      this.http.post(
        `${this.crudConfig.getApiUrl()}/auth/authorization-code`,
        loginRequest.toRequestBody(),
      ).subscribe({
        next: (response) => {
          this.authService.handleLoginSuccess(loginRequest, response);
          this.router.stateService.go('dashboard');
        },
        error: () => {
          this.router.stateService.go(this.authService.getRedirectState());
        }
      });
    }
  }
}
