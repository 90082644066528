export enum AccountEntityTypes {
  God = 'God',
  ApplicationUser = 'ApplicationUser',
}

export enum AccountTypes {
  God = 'God',
  ApplicationUser = 'ApplicationUser',
}

export enum AddressTypes {
  CorrespondenceAddress = 'correspondence_address',
  VisitingAddress = 'visiting_address',
  HeadquartersAddress = 'headquarters_address',
}

export enum ApplicationUserRoles {
  Administrator = 'administrator',
  User = 'user',
}

export enum ApprovalStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REVOKED = 'revoked',
  REJECTED = 'rejected',
}

export enum StatusesEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum AuthenticationGrantTypes {
  CREDENTIALS = 'credentials',
  PORTAL_CREDENTIALS = 'portal_credentials',
  REFRESH_TOKEN = 'refresh_token',
  CLIENT_CREDENTIALS = 'client_credentials',
  SAML = 'saml',
  AUTHORIZATION_CODE = 'authorization_code',
  GOOGLE_AUTHENTICATOR = 'google_authenticator',
}

export enum Countries {
  NL = 'NL',
  DE = 'DE',
  BE = 'BE',
}

export enum Mode {
  'ADD' = 'ADD',
  'EDIT' = 'EDIT',
  'VIEW' = 'VIEW',
}

export enum SettingTypes {
  OPEN_ID = 'open-id',
  LANGUAGES = 'languages',
}

export enum WebsocketMessageTypes {
  ID = 'id',
  PING = 'ping',
  PONG = 'pong',
  DOWNLOAD = 'download',
  NOTIFICATION = 'notification',
  UPDATE = 'update',
  MESSAGE = 'message',
  LOGOUT = 'logout',
}

export enum WeekDay {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum OpenIdCodeChallengeMethod {
  S256 = 'S256',
}

export enum AuthenticationMethodEnum {
  LOCAL = 'local',
  SAML = 'saml',
  OPEN_ID = 'openid',
}

export enum PortalIdentityProviders {
  DIGI_D = 'digid',
}

export enum SamlIdentifierFormatEnum {
  PERSISTENT = "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent",
  TRANSIENT = "urn:oasis:names:tc:SAML:2.0:nameid-format:transient",
  EMAIL_ADDRESS = "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress",
  UNSPECIFIED = "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified",
  X509_SUBJECT_NAME = "urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName",
  WINDOWS_DOMAIN_QUALIFIED_NAME = "urn:oasis:names:tc:SAML:1.1:nameid-format:WindowsDomainQualifiedName",
  KERBEROS = "urn:oasis:names:tc:SAML:2.0:nameid-format:kerberos",
  ENTITY = "urn:oasis:names:tc:SAML:2.0:nameid-format:entity",
}

export enum SamlSignatureAlgorithmEnum {
  SHA1 = "sha1",
  SHA256 = "sha256",
  SHA512 = "sha512",
}

export enum UserRoleEnum {
  GOD = 'god',
  ADMINISTRATOR = 'administrator',
  LOCATION_MANAGER = 'location-manager',
  TEAM_LEADER = 'team-leader',
  MENTOR = 'mentor',
  CLIENT = 'client'
}

export enum TargetsEnum {
  CLIENTS = 'clients',
  EMPLOYEES = 'employees'
}

export enum MediaTypesEnum {
  NONE = 'none',
  UPLOAD_AUDIO = 'upload-audio',
  UPLOAD_IMAGE = 'upload-image',
  STREAM_VIDEO = 'stream-video'
}

export enum TypesEnum {
  MIJNMENING = 'mijn-mening',
  FREESTYLE = 'freestyle'
}

export enum DisplaysEnum {
  LIST = 'list',
  PAGINATED = 'paginated'
}

export enum SurveyStatusEnum {
  PLANNED     = 'planned',
  PARTIAL     = 'partial',
  COMPLETED   = 'completed',
  EXPIRED     = 'expired',
  NO_RESPONSE = 'no-response',
}

export enum RecurrenceEnum {
  NONE       = 'none',
  MONTH      = 'month',
  QUARTER    = 'quarter',
  SEMIANNUAL = 'semiannual'
}

export enum SurveyReportTypeEnum {
  RESPONSE = 'response',
  FEEDBACK = 'feedback',
  RESPONSE_FEEDBACK = 'response-feedback',
  RESPONSE_FEEDBACK_CAREPLAN = 'response-feedback-careplan',
}

export function getEnumValues(enumeration: any): string[] {
  return Object.keys(enumeration)
    .map(key => enumeration[key])
    .filter(value => typeof value === 'string');
}
