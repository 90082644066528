export const translations = {
  version: 'Versienummer',
  environment: 'Omgeving',
  sidebar: {
    navigation: {
      dashboard: 'Dashboard',
      questionCategory: 'Vraagcategorieën',
      questionnaire: 'Vragenlijsten',
      planning: 'Metingen',
      user: 'Gebruikers',
      client: 'Cliënten',
      employee: 'Medewerkers',
      place: 'Plaatsen',
      costCenter: 'Kostenplaatsen',
      location: 'Locaties',
      whiteLabel: 'Whitelabels',
    },
  },
  form: {
    choose: {
      multiple: 'Selecteer 1 of meerdere opties',
      single: 'Maak een keuze',
    },
    noResultsFound: 'Geen data beschikbaar',
    type: 'Type',
    enabled: 'Ingeschakeld',
  },
  boolean: {
    true: 'Ja',
    false: 'Nee',
  },
  gender: {
    male: 'Man',
    female: 'Vrouw',
    unknown: 'Onbekend',
  },
  status: {
    active: 'Actief',
    inactive: 'Inactief',
    planned: 'Ingepland',
    partial: 'Deels ingevuld',
    completed: 'Ingevuld',
    expired: 'Verlopen',
    'no-response': 'Non-response',
    total: 'Totaal uitgestuurd',
  },
  confirmation: {
    title: 'Let op!',
    text: 'Weet u zeker dat u het item wilt verwijderen?',
    archive: 'Weet u zeker dat u het item wilt archiveren?',
    confirm: 'Ja',
    decline: 'Nee',
    acknowledge: 'Oke',
    deleteAttachment: 'Weet u zeker dat u deze bijlage wilt verwijderen?',
    leavePage: {
      text: 'Weet u zeker dat u de pagina wilt verlaten? Niet alle wijzigingen zijn opgeslagen.',
    },
  },
  authentication: {
    login: {
      failed: 'Uw e-mailadres of wachtwoord is niet juist.',
      expired: 'Uw loginsessie is verlopen. Log a.u.b. opnieuw in.',
      success: 'U bent succesvol ingelogd',
    },
    forgotpassword: {
      success:
        'Uw wachtwoord vergeten verzoek is succesvol verstuurd. U ontvangt binnen enkele minuten een e-mail met meer informatie.',
      failed: 'Uw wachtwoord vergeten verzoek is mislukt. Probeer het nog eens.',
    },
    passwordrestore: {
      success: 'Uw wachtwoord is succesvol opgeslagen. U kunt nu inloggen met uw nieuwe wachtwoord.',
      failed:
        'Wachtwoord instellen mislukt. Een wachtwoord moet minimaal uit 8 tekens, ' +
        '1 hoofdletter, 1 kleine letter en 1 leesteken bestaan',
      criteria: {
        minLength: 'minimaal {{number}} karakters',
        minLowercase: 'minimaal {{number}}  kleine letters',
        minUppercase: 'minimaal {{number}} hoofdletters',
        minNumbers: 'minimaal {{number}} getallen',
        minSpecialChars: 'minimaal {{number}} speciale karakters',
      },
    },
    register: {
      registerAsApplicant: 'Registreren als aanvrager',
      checkMailbox: 'Controleer uw mailbox!',
      successful: 'Uw registratie is gelukt en het account is aangemaakt.',
      emailHasBeenSent:
        'Er is een e-mail naar het opgegeven e-mailadres verzonden met een link om uw wachtwoord in te stellen.',
    },
  },
  role: {
    god: 'god',
    administrator: 'Applicatiebeheerder',
    'location-manager': 'Locatiebeheerder',
    'team-leader': 'Teamleider',
    mentor: 'Begeleider',
    client: 'Cliënt',
  },
  notification: {
    action: {
      saved: 'opgeslagen',
      deleted: 'verwijderd',
      created: 'aangemaakt',
      copied: 'gekopieerd',
    },
    generic: {
      item: 'Item',
      info: 'Informatie',
      warning: 'Waarschuwing',
      error: 'Foutmelding',
    },
    success: {
      create: '{{crudEntity}} succesvol opgeslagen',
      update: '{{crudEntity}} succesvol bijgewerkt',
      delete: '{{crudEntity}} succesvol verwijderd',
      patch: '{{crudEntity}} successvol bijgewerkt',
    },
    error: {
      read: '{{crudEntity}} ophalen is mislukt',
      create: '{{crudEntity}} opslaan mislukt',
      update: '{{crudEntity}} bijwerken mislukt',
      delete: '{{crudEntity}} verwijderen mislukt',
      list: 'Ophalen van de lijst is mislukt',
      patch: '{{crudEntity}} bijwerken mislukt',
    },
    unauthorized: {
      create: 'Geen toestemming om {{crudEntity}} op te slaan',
      update: 'Geen toestemmig om {{crudEntity}} te bewerken',
      patch: 'Geen toestemmig om {{crudEntity}} bij te werken',
      delete: 'Geen toestemming om {{crudEntity}} te verwijderen',
      list: 'Geen toestemming om lijst in te zien',
      read: 'Geen toestemming om {{crudEntity}} in te zien',
    },
    custom: {
      success: {
        action: {
          done: 'Actie succesvol voltooid',
        },
        assignment: {
          done: 'Taak succesvol voltooid.',
        },
      },
    },
  },
  crudEntity: {
    dashboard: 'Dashboard',
    'question-category': 'Vraagcategorie',
    questionnaire: 'Vragenlijst',
    question: 'Vraag',
    planning: 'Meting',
    client: 'Cliënt',
    employee: 'Medewerker',
    location: 'Locatie',
    'cost-center': 'Kostenplaats',
    'white-label': 'Whitelabel',
  },
  enum: {
    applicationUserRoles: {
      administrator: 'Administrator',
      user: 'Gebruiker',
    },
    addressCountries: {
      NL: 'Nederland',
      DE: 'Duitsland',
      BE: 'België',
    },
  },
  action: {
    add: "Nieuwe toevoegen",
    view: 'Bekijken',
    edit: 'Bewerken',
    delete: 'Verwijderen',
    save: 'Opslaan',
    cancel: 'Annuleren',
    back: 'Terug',
    link: 'Koppelen',
    reset: 'Wissen',
    export: 'Exporteren als .csv',
    import: 'Importeren',
    register: 'Registreren',
    open: 'Openen',
    copy: "Kopiëren",
    archive: "Archiveren",
    restore: "Terugzetten",
    next: "Volgende",
    previous: "Vorige",
    exportCsv: "Exporteren als .csv",
    downloadReport: "Rapportage downloaden",
    downloadReportType: {
      results: 'Resultaten',
      feedback: 'Verbeteracties',
      careplan: 'Zorgplan',
    },
    settings: "Instellingen",
    costCenter: "Kostenplaatsen",
    location: "Locaties",
    goback: "Terug",
    reorder: "Herschikken",
    download: "Download",
    exportCostcenter: "Exporteer een kostenplaats",
    ownLocations: "Eigen locaties",
    showArchive: "Toon archief",
    hideArchive: "Toon lijst"
  },
  errors: {
    required: 'Dit veld is verplicht',
    invalid: 'Dit veld heeft geen geldige waarde',
    passwordMatch: 'Wachtwoord komt niet overeen',
  },
  model: {
    location: {
      name: 'Naam',
      externalId: 'Extern id',
    },
    client: {
      clientNumber: 'Cliëntnr.',
      startDate: 'Startdatum (van)',
      endDate: 'Einddatum (tot)',
    },
    employee: {
      employeeNumber: 'Personeelsnr.',
      authRole: 'Autorisatierol',
    },
    user: {
      enabled: 'Actief',
      firstName: 'Voornaam',
      middleName: 'Tussenvoegsel',
      lastName: 'Achternaam',
      gender: 'Geslacht',
      dateOfBirth: 'Geboortedatum',
      email: 'E-mail',
      role: 'Rol',
      status: 'Status',
      mobileAppUser: 'Mobiele app gebruiker',
      username: 'Gebruikersnaam',
      phoneNumber: 'Mobiel telefoonnr.',
      password: 'Wachtwoord',
      passwordConfirm: 'Wachtwoord bevestigen',
    },
    questionCategory: {
      title: 'Titel',
      description: 'Beschrijving',
      type: 'Type categorie',
    },
    question: {
      title: 'Vraag',
      description: 'Beschrijving',
      category: 'Vraagcategorie',
      mediaType: 'Type bijlage',
      attachment: 'Bijlage',
      streamUrl: 'Vul hier de URL van de videostream in',
      mediaCrop: 'Afbeelding bijsnijden',
      required: 'Deze vraag verplicht maken',
      ageDependent: 'Vraag afhankelijk van leeftijd',
      answerType: 'Soort antwoord',
      selectAmount: 'Selecteer het aantal',
      answers: 'Antwoorden',
      multipleChoice: 'Meerdere antwoorden mogelijk',
      addOptionComment: 'Opmerkingen veld',
    },
    questionnaire: {
      title: 'Titel',
      type: 'Type vragenlijst',
      masterType: 'Type MijnMening lijst',
      choicesAmount: 'Aantal smiley\'s',
      location: 'Locatie',
      status: 'Status',
      display: 'Weergave vragen',
      logoCurrent: 'Huidig logo',
      logo: 'Logo',
    },
    planning: {
      title: 'Titel',
      planningMoments: 'Meetmomenten',
      startDate: 'Startdatum (van)',
      endDate: 'Einddatum (tot)',
      noEnd: 'Deze meting heeft geen einddatum',
      questionnaire: 'Vragenlijst',
    },
    costCenter: {
      name: 'Naam',
      externalId: 'Extern id',
      location: 'Locatie',
    },
    whiteLabel: {
      name: 'Naam',
      user: {
        blockTitle: 'Applicatiebeheerder instellingen',
        firstName: 'Voornaam',
        middleName: 'Tussenvoegsel',
        lastName: 'Achternaam',
        email: 'E-mailadres',
        username: 'Gebruikersnaam',
        password: 'Wachtwoord',
      },
      forgotPasswordSettings: {
        blockTitle: 'Wachtwoord vergeten e-mail instellingen',
        logoUrl: 'Url van het logo',
        primaryColor: 'Primaire kleur',
        secondaryColor: 'Secundaire kleur',
      },
      authenticationMethod: 'Authenticatie methode',
      ssoFrontendUrl: 'SSO Frontend URL (origin)',
      saml: {
        idpMetadataUrl: 'IDP Metadata URL',
        appId: 'App ID',
        identifierFormat: 'Identifier format',
        disableRequestedAuthnContext: 'Verwijder RequestedAuthnContext van AuthnRequest',
        signatureAlgorithm: 'Signature algorithm',
      },
      openId: {
        wellKnownUrl: '.well-known URL',
        clientId: 'Client ID',
        clientSecret: 'Client secret',
        scope: 'Scope',
      },
    },
  },
  type: {
    "mijn-mening": "MijnMening",
    freestyle: "Vrije",
  },
  display: {
    list: 'Lijst',
    paginated: 'Pagina gescheiden',
  },
  target: {
    clients: "Cliënten",
    employees: "Medewerkers"
  },
  mediaType: {
    none: 'Geen',
    'upload-audio': 'Audio',
    'upload-image': 'Afbeelding',
    'stream-video': 'Videostream'
  },
  components: {
    user: {
      overview: {
        grid: {
          filter: {
            name: 'Zoek op naam',
          },
          column: {
            firstName: 'Voornaam',
            lastName: 'Achternaam',
            status: 'Status'
          },
        },
      },
      detail: {
        action: {
          edit: 'Bewerken',
          costCenter: 'Kostenplaatsen',
          location: 'Locaties',
        },
        personal: {
          blockTitle: 'Persoonsgegevens',
          column: {
            employeeNr: 'Personeelsnr.',
            clientNr: 'Cliëntnr.',
            firstName: 'Voornaam',
            middleName: 'Tussenvoegsel',
            lastName: 'Achternaam',
            dateOfBirth: 'Geboortedatum',
            gender: 'Geslacht',
            location: 'Locatie(s)',
            costCenter: 'Kostenplaats(en)',
          },
        },
        mijnMening: {
          blockTitle: 'MijnMening gegevens',
          column: {
            authRole: 'Autorisatierol',
            email: 'E-mailadres',
            startDate: 'Startdatum (van)',
            endDate: 'Einddatum (tot)',
            status: 'Status',
          },
        },
        account: {
          blockTitle: 'Accountgegevens',
          column: {
            mobileAppUser: 'Mobiele app gebruiker',
            userName: 'Gebruikersnaam',
            password: 'Wachtwoord',
            phoneNr: 'Mobiel telefoonnr.',
          },
        },
      },
      form: {
        blockTitle: {
          personal: 'Persoonsgegevens',
          mijnMening: 'MijnMening gegevens',
          account: 'Accountgegevens',
        },
      },
      costCenter: {
        pageTitle: 'Kostenplaatsen',
        grid: {
          filter: {
            externalId: 'Zoek op extern id',
          },
          column: {
            name: 'Naam',
            externalId: 'Extern id',
          },
        },
      },
    },
  },
  states: {
    generic: {
      noResultsFound: 'Geen resultaten gevonden',
    },
    dashboard: {
      pageTitle: 'Dashboard',
    },
    questionCategory: {
      pageTitle: 'Vraagcategorieën',
      overview: {
        pageTitle: 'Overzicht vraagcategorieën',
        buttonAdd: 'Nieuwe vraagcategorie toevoegen',
        grid: {
          column: {
            title: 'Titel',
            type: 'Type vragenlijst',
          },
          filter: {
            title: 'Zoek op titel',
          },
        },
      },
      form: {
        add: {
          pageTitle: 'Vraagcategorie toevoegen',
        },
        edit: {
          pageTitle: 'Vraagcategorie bewerken',
        },
      },
    },
    questionnaire: {
      pageTitle: 'Vragenlijsten',
      pageTitleSuffix: 'archief',
      overview: {
        pageTitle: 'Overzicht vragenlijsten',
        buttonAdd: 'Nieuwe vragenlijst toevoegen',
        showArchive: 'Toon archief',
        hideArchive: 'Toon lijst',
        grid: {
          filter: {
            title: 'Zoek op titel',
            location: 'Locatie',
          },
          column: {
            title: 'Titel',
            type: 'Type',
            target: 'Doelgroep',
            state: 'Status',
          },
        }
      },
      form: {
        add: {
          pageTitle: 'Vragenlijst toevoegen',
        },
        edit: {
          pageTitle: 'Vragenlijst bewerken',
        },
      },
    },
    question: {
      pageTitle: 'Vragen',
      overview: {
        pageTitle: 'Overzicht vragen',
        buttonAdd: 'Nieuwe vraag toevoegen',
        buttonReorder: 'Herschikken',
        grid: {
          filter: {
            title: 'Zoek op titel',
          },
          column: {
            title: 'Titel',
            questionCategory: 'Vraagcategorie',
          },
        },
      },
      form: {
        add: {
          pageTitle: 'Vraag toevoegen',
        },
        edit: {
          pageTitle: 'Vraag bewerken',
        },
      },
    },
    planning: {
      pageTitle: 'Metingen',
      overview: {
        pageTitle: 'Overzicht metingen',
        buttonAdd: 'Meting toevoegen',
        grid: {
          filter: {
            title: 'Zoek op titel',
          },
          column: {
            title: 'Titel',
            startDate: 'Startdatum',
            endDate: 'Einddatum',
          },
        },
      },
      form: {
        add: {
          pageTitle: 'Meting toevoegen',
        },
        edit: {
          pageTitle: 'Meting bewerken',
        },
      },
    },
    planningIteration: {
      pageTitle: 'Metingen',
      overview: {
        pageTitle: 'Iteraties',
        grid: {
          column: {
            title: 'Titel',
            startDate: 'Startdatum',
            endDate: 'Einddatum',
            completed: 'Afgerond',
          },
        },
      },
      detail: {
        titles: {
          stats: 'Statistieken',
          exportSurveys: 'Enquetes exporteren',
          exportReport: 'Rapportage exporteren',
          surveys: 'Enquetes',
        },
        action: {
          downloadCsv: 'Exporteren als .csv',
          downloadPdf: 'Exporteren als .pdf',
          selectCostCenter: '-- Selecteer kostenplaats --',
        },
        stats: {
          planned: 'Ingepland',
          partial: 'Deels ingevuld',
          completed: 'Ingevuld',
          expired: 'Verlopen',
          noResponse: 'Non-response',
          total: 'Totaal uitgestuurd',
        },
      },
    },
    survey: {
      overview: {
        grid: {
          column: {
            clientNumber: 'Cliëntnr.',
            firstName: 'Voornaam',
            lastName: 'Achternaam',
            costCenter: 'Kostenplaats',
            status: 'Status',
          },
        },
      },
      detail: {
        pageTitle: 'Rapportage',
        generic: {
          name: 'Naam',
          questionnaire: 'Vragenlijst',
          date: 'Invuldatum',
          score: 'Beoordeling',
        },
        noAnswerPossible: 'Geen antwoord mogelijk',
        grid: {
          column: {
            title: 'Vraag',
            score: 'Antwoord',
            remark: 'Opmerking',
            feedback: 'Verbeteractie',
          },
        },
      },
    },
    users: {
      pageTitle: 'Gebruikers',
    },
    client: {
      pageTitle: 'Cliënten',
      overview: {
        pageTitle: 'Overzicht cliënten',
        buttonAdd: 'Nieuwe cliënt toevoegen',
        grid: {
          column: {
            clientNumber: 'Cliëntnr.',
          },
        },
      },
      form: {
        add: {
          pageTitle: 'Cliënt toevoegen',
        },
        edit: {
          pageTitle: 'Cliënt aanpassen',
        },
      },
    },
    employee: {
      pageTitle: 'Medewerkers',
      overview: {
        pageTitle: 'Overzicht medewerkers',
        buttonAdd: 'Nieuwe medewerker toevoegen',
        grid: {
          column: {
            employeeNumber: 'Personeelsnr.',
            role: 'Authorisatierol',
          },
        },
      },
      form: {
        add: {
          pageTitle: 'Medewerker toevoegen',
        },
        edit: {
          pageTitle: 'Medewerker aanpassen',
        },
      },
    },
    costCenter: {
      pageTitle: 'Kostenplaatsen',
      overview: {
        pageTitle: 'Overzicht kostenplaatsen',
        buttonAdd: 'Nieuwe kostenplaats toevoegen',
        grid: {
          filter: {
            nameAndId: 'Zoek op naam en id'
          },
          column: {
            externalId: 'Extern id',
            name: 'Naam',
            location: 'Locatie',
          },
        },
      },
      form: {
        add: {
          pageTitle: 'Kostenplaats toevoegen',
        },
        edit: {
          pageTitle: 'Kostenplaats bewerken',
        },
      },
    },
    places: {
      pageTitle: 'Plaatsen',
    },
    location: {
      pageTitle: 'Locaties',
      overview: {
        pageTitle: 'Overzicht locaties',
        buttonAdd: 'Nieuwe locatie toevoegen',
        grid: {
          filter: {
            nameAndId: 'Zoek op naam en id',
          },
          column: {
            externalId: 'Extern id',
            name: 'Naam',
          },
        },
      },
      form: {
        add: {
          pageTitle: 'Locatie toevoegen',
        },
        edit: {
          pageTitle: 'Locatie bewerken',
        },
      },
    },
    profile: {
      pageTitle: 'Profile',
      form: {
        pageTitle: 'Profile',
        passwordBlock: 'Wachtwoord',
      },
    },
    ssoUnauthorized: {
      happyBlock: {
        title: '🥳 Hoera!',
        text: 'Je bent succesvol ingelogd en we hebben je identiteit kunnen verifiëren!',
      },
      sadBlock: {
        title: '😭 Maar helaas...',
        text: '...heb je (nog) geen toegang tot deze applicatie. Neem contact op met de applicatie beheerder om je te voorzien van de juiste rechten.',
      },
    },
    unexpectedError: {
      title: 'Ohow...🧨💣',
      text: 'Er is een onverwachte fout opgetreden. Probeer het later nog eens.',
    },
    whiteLabel: {
      pageTitle: 'Whitelabels',
      overview: {
        pageTitle: 'Whitelabel overzicht',
        buttonAdd: 'Nieuw whitelabel toevoegen',
        grid: {
          filter: {
            name: 'Zoek op naam',
          },
          column: {
            name: 'Naam',
            number: 'Number',
          },
        },
      },
      form: {
        add: {
          pageTitle: 'Whitelabel toevoegen',
        },
        edit: {
          pageTitle: 'Whitelabel bewerken',
        },
      },
    },
  },
  header: {
    profile: {
      navigation: {
        profile: 'Profiel',
        logout: 'Uitloggen',
      },
    },
    download: {
      noItems: 'Geen downloads',
      generating: 'Bezig met genereren...',
      generatingProgress: 'Bezig met genereren: {{ percentage }}%',
    },
  },
  layout: {
    loginBlockTitle: 'Inloggen',
    loginBlockSubTitle: 'Login met je gebruikersnaam en wachtwoord.',
    forgotPasswordBlockTitle: 'Wachtwoord vergeten',
    passwordRestoreBlockTitle: 'Wachtwoord instellen',
    passwordRestoreBlockSubTitle:
      'Het wachtwoord moet minimaal uit 8 tekens bestaan en minimaal 1 hoofdletter, 1 kleine letter en 1 leesteken bevatten',
    usernamePlaceholder: 'Gebruikersnaam',
    passwordPlaceholder: 'Wachtwoord',
    passwordConfirmationPlaceholder: 'Wachtwoord bevestigen',
    forgotPasswordText: 'Wachtwoord vergeten',
    forgotPasswordIcon: 'mdi-help-circle',
    loginText: 'Inloggen',
    loginIcon: 'mdi-lock',
    back: 'Terug',
    requestPassword: 'Versturen',
    passwordRestore: 'Wijzigen',
    registerText: 'Registreren',
    registerIcon: 'mdi-account',
  },
  crud: {
    dataError: {
      2001: 'Niet alle verplichte velden zijn ingevuld',
      3000: 'Ongeldige gebruikersnaam',
      3004: 'Wachtwoord voldoet niet aan minimale criteria: min. 8 karakters lang en min. 1 letter, cijfer, speciaal karakter en hoofdletter verplicht.',
      36000: 'Ongeldige titel voor referendum',
      36001: 'Ongeldige beschrijving voor referendum',
      36002: 'Ongeldige besluit beschrijving voor referendum',
      36003: 'Ongeldige URL voor referendum',
      36004: 'Ongeldig referendum',
      36005: 'De URL is al in gebruik door een ander referendum',
      36006: 'Referndum kan niet verwijderd worden omdat er al stemmen zijn uitgebracht',
      37000: 'Ongeldige stem',
      37001: 'Stem heeft geen geldig type (analoog/digitaal)',
      37002: 'Stem heeft geen geldig type (analoog/digitaal)',
      37003: 'Stemmen niet meer mogelijk. Referendum is gesloten',
      37004: 'Ingevulde BSN is niet geldig',
      37005: 'BSN is niet ingevuld',
      37006: 'Dit BSN heeft al een stem uitgebracht op dit referendum',
      37007: 'BSN heeft geen toestemming om stem uit te brengen',
      37008: 'Stem kan niet verwijderd worden. Referendum is gesloten',
      37009: 'Alleen handmatig toegevoegde stemmen kunnen verwijderd worden',
      37010: 'Import csv-bestand mist in upload',
      37011: 'Niet gelukt om import csv-bestand te openen',
      37012: 'Niet gelukt om import csv-bestand lezen',
      37013: 'Import csv-bestand bevat geen data',
      37014: 'Import csv-bestand bevat geen data',
    },
  },
  weekday: {
    monday: 'maandag',
    tuesday: 'dinsdag',
    wednesday: 'woensdag',
    thursday: 'donderdag',
    friday: 'vrijdag',
    saturday: 'zaterdag',
    sunday: 'zondag',
  },
  unitsOfMeasure: {
    kilometer: {
      short: 'km',
      long: 'Kilometer',
    },
  },
  images: {
    error: {
      length: 'Maximaal {{maxImages}} afbeeldingen toegestaan',
    },
  },
  countries: {
    NL: 'Nederland',
    BE: 'België',
    DE: 'Duitsland',
  },
  answerType: {
    star: 'Ster',
    smiley: 'Smiley',
    likert: 'Beoordelingschaal',
    slider: 'Schuifregelaar',
    'multiple-choice': 'Meerkeuze',
    text: 'Invulveld',
    none: 'Statische tekst',
  },
  recurrence: {
    none: 'Eenmalig',
    month: 'Elke maand',
    quarter: 'Elk kwartaal',
    semiannual: 'Elk half jaar',
  },
  rating: {
    1: 'Helemaal niet tevreden',
    2: 'Niet echt tevreden',
    3: 'Een beetje tevreden',
    4: 'Best wel tevreden',
    5: 'Erg tevreden',
  },
  authenticationMethod: {
    local: 'Lokaal',
    saml: 'SAML',
    openid: 'Open ID Connect',
  },
  signatureAlgorithm: {
    sha1: 'SHA1',
    sha256: 'SHA256',
    sha512: 'SHA512',
  },
};
