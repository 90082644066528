import { LocaleService } from '@ngx-ivengi/locale';
import { routes } from '../assets/i18n/routes';

export function setLanguage(): void {
  const currentLanguage = LocaleService.getCurrentLanguageISO();
  let targetLanguage: any;

  if (window.location.pathname) {
    const languages = [
      currentLanguage,
      ...Object.keys(routes).filter(routeLanguage => routeLanguage !== currentLanguage),
    ];

    languages.forEach((languageISO: any) => {
      if (targetLanguage) {
        return;
      }

      const stateToUrl: any = {};

      // first iteration (convert the array of objects to keyvalue and check if a simple match is found)
      (routes as any)[languageISO].forEach(({ state, url }: any) => {
        if (targetLanguage) {
          return;
        }

        // if url matches and there's not DOT within the state (simple match)
        const urlMatch = url.substring(0, url.indexOf('?') === -1 ? url.length : url.indexOf('?'));
        if (window.location.pathname === urlMatch && state.indexOf('.') === -1) {
          targetLanguage = languageISO;
          return;
        }

        stateToUrl[state] = url;
      });
    });
  }

  if (targetLanguage && targetLanguage !== currentLanguage) {
    LocaleService.setCurrentLanguageISO(targetLanguage);
  }
}
