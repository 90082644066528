import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrudConfig } from '@ngx-ivengi/crud';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService, AuthorizationCodeLoginRequest } from '@ngx-ivengi/authentication';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../whitelabel/environment';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  private static refererUrlPropName = 'referer_url';
  private static codeChallengePropName = 'code_challenge';
  private static codeChallengeMethodPropName = 'code_challenge_method';
  private static codeVerifierPropName = 'code_verifier';

  public constructor(
    private http: HttpClient,
    private config: CrudConfig,
    private authService: AuthenticationService,
    private cookieService: CookieService,
  ) {}

  public isEnabled(): Observable<boolean> {
    return this.http
      .get(`${this.config.getApiUrl()}/authentication/sso/enabled`)
      .pipe(
        map(response => {
          return (response as { enabled: boolean }).enabled;
        }),
      )
      .pipe(
        catchError(() => {
          return of(false);
        }),
      );
  }

  public startAuthentication() {
    const pkce = this.authService.generatePKCE();
    const cookieExpiration = moment().add(5, 'minutes');
    const secure = /https:/.test(window.location.protocol);
    const sameSite = secure ? 'None' : 'Lax';
    this.cookieService.set(
      SsoService.codeChallengePropName,
      pkce.code_challenge,
      cookieExpiration.toDate(),
      '/',
      undefined,
      secure,
      sameSite,
    );
    this.cookieService.set(
      SsoService.codeVerifierPropName,
      pkce.code_verifier,
      cookieExpiration.toDate(),
      '/',
      undefined,
      secure,
      sameSite,
    );

    const httpParams = new HttpParams({
      fromObject: {
        [SsoService.refererUrlPropName]: window.location.origin,
        [SsoService.codeChallengePropName]: pkce.code_challenge,
        [SsoService.codeChallengeMethodPropName]: 's256',
        organization: environment.whiteLabel.number,
      },
    });
    window.location.href = `${this.config.getApiUrl()}/authentication/sso?${httpParams.toString()}`;
  }

  public handleAuthorizationCode() {
    if (window.location.search) {
      const url = new URL(window.location.href);
      if (url.searchParams.get('authorization_code')) {
        // Always set remember me enabled for OpenID connect logins on request
        localStorage.setItem('remember_me', true.toString());

        this.authService.config.setRememberMeEnabled(true);
        const authorizationCode = url.searchParams.get('authorization_code') as string;
        const request = new AuthorizationCodeLoginRequest(authorizationCode, {
          code_verifier: this.cookieService.get(SsoService.codeVerifierPropName),
        });
        this.authService.login(request).subscribe();
      }
    }
    return of(true);
  }
}
