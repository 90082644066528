import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudConfig } from '@ngx-ivengi/crud';
import { Observable, publishLast } from 'rxjs';
import { AuthenticationService } from '@ngx-ivengi/authentication';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private config: CrudConfig,
    private http: HttpClient,
    private authService: AuthenticationService,
  ) {}

  public getProfile(): Observable<any> {
    return this.http.get(`${this.config.getApiUrl()}/user/${this.authService.getJWTClaims().sub}`)
      .pipe(map((data: any): User => {
        const user = new User();
        user.fromResponse(data);
        return user;
      }));
  }
}
